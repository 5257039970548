var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoaded)?_c('div',[(!_vm.company.has_paid_subscription)?_c('b-jumbotron',{attrs:{"bg-variant":"info","text-variant":"white","border-variant":"light"},scopedSlots:_vm._u([{key:"lead",fn:function(){return [_vm._v(" Sie müssen ein kostenpflichtes Abonement abschliessen um von unserem Vermittlerprogramm profitieren zu können. ")]},proxy:true}],null,false,674129514)},[_c('router-link',{attrs:{"to":"/adjust-subscription"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('b-button',{attrs:{"variant":"success"},on:{"click":navigate}},[_vm._v("Abonement abschliessen")])]}}],null,false,4127880244)})],1):_c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-body py-8 px-8 py-lg-20 px-lg-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"offset-xxl-2 col-xxl-8"},[_c('h4',{staticClass:"mb-10 font-weight-bold text-dark"},[_vm._v(" Vermittlungen ")]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-head-custom table-vertical-center table-head-bg table-borderless"},[_c('thead',[_c('tr',{staticClass:"text-left"},[_c('th',{staticStyle:{"min-width":"120px"}},[_vm._v("Datum")]),_c('th',{staticStyle:{"min-width":"120px"}},[_vm._v("Vermittelter Kunde")]),_c('th',{staticStyle:{"min-width":"120px"}},[_vm._v("Unternehmen")]),_c('th',{staticClass:"text-right",staticStyle:{"min-width":"100px"}},[_vm._v("Status")]),_c('th',{staticClass:"text-right",staticStyle:{"min-width":"100px"}},[_vm._v(" Provision ")])])]),_c('tbody',[_vm._l((_vm.userInvitations),function(userInvitation,n){return [_c('tr',{key:'S' + n},[_c('td',{staticClass:"py-5"},[_c('span',{staticClass:"text-dark-75 d-block font-size-lg"},[_vm._v(_vm._s(_vm.formatDataTimeWithTime(userInvitation.created_at)))])]),_c('td',{staticClass:"py-5"},[_c('span',{staticClass:"text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(_vm._s(userInvitation.invited_user.first_name)+" "+_vm._s(userInvitation.invited_user.last_name))])]),_c('td',{staticClass:"py-5"},[_c('span',{staticClass:"text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(_vm._s(userInvitation.has_invited_company ? userInvitation.invited_company.company_name : "-")+" ")])]),_c('td',{staticClass:"text-right"},[_c('span',{staticClass:"label label-lg label-inline",class:("label-light-" + (_vm.getInvitationsStatusColor(
                            userInvitation
                          )))},[_vm._v(" "+_vm._s(userInvitation.invitation_status_display)+" ")])]),_c('td',{staticClass:"text-right"},[_c('span',{staticClass:"text-dark-75 d-block font-size-lg"},[_vm._v("CHF "+_vm._s(_vm.formatFloat(userInvitation.paid_out_commission)))])])])]})],2)])])])])])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
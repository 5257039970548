<template>
  <div v-if="isLoaded">
    <!--begin::Notice-->
    <b-jumbotron
      v-if="!company.has_paid_subscription"
      bg-variant="info"
      text-variant="white"
      border-variant="light"
    >
      <template v-slot:lead>
        Sie müssen ein kostenpflichtes Abonement abschliessen um von unserem
        Vermittlerprogramm profitieren zu können.
      </template>
      <!--      <hr class="my-4" />-->
      <!--      <p>-->
      <!--        Sie können jedoch weitere Optionen jederzeit zu Ihrem Abonoment-->
      <!--        hinzufügen.-->
      <!--      </p>-->
      <router-link
        to="/adjust-subscription"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <b-button variant="success" @click="navigate"
          >Abonement abschliessen</b-button
        >
      </router-link>
    </b-jumbotron>
    <!--end::Notice-->

    <!--begin::Card-->
    <div class="card card-custom" v-else>
      <!--begin::Invitations-->
      <div class="card-body py-8 px-8 py-lg-20 px-lg-10">
        <div class="row">
          <div class="offset-xxl-2 col-xxl-8">
            <h4 class="mb-10 font-weight-bold text-dark">
              Vermittlungen
            </h4>

            <!--begin::Table-->
            <div class="table-responsive">
              <table
                class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              >
                <thead>
                  <tr class="text-left">
                    <th style="min-width: 120px">Datum</th>
                    <th style="min-width: 120px">Vermittelter Kunde</th>
                    <th style="min-width: 120px">Unternehmen</th>
                    <th style="min-width: 100px" class="text-right">Status</th>
                    <th style="min-width: 100px" class="text-right">
                      Provision
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(userInvitation, n) in userInvitations">
                    <tr v-bind:key="'S' + n">
                      <td class="py-5">
                        <span class="text-dark-75 d-block font-size-lg">{{
                          formatDataTimeWithTime(userInvitation.created_at)
                        }}</span>
                      </td>

                      <td class="py-5">
                        <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          >{{ userInvitation.invited_user.first_name }}
                          {{ userInvitation.invited_user.last_name }}</span
                        >
                      </td>
                      <td class="py-5">
                        <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          >{{
                            userInvitation.has_invited_company
                              ? userInvitation.invited_company.company_name
                              : "-"
                          }}
                        </span>
                      </td>
                      <td class="text-right">
                        <span
                          class="label label-lg label-inline"
                          v-bind:class="
                            `label-light-${getInvitationsStatusColor(
                              userInvitation
                            )}`
                          "
                        >
                          {{ userInvitation.invitation_status_display }}
                        </span>
                      </td>
                      <td class="text-right">
                        <span class="text-dark-75 d-block font-size-lg"
                          >CHF
                          {{
                            formatFloat(userInvitation.paid_out_commission)
                          }}</span
                        >
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <!--end::Table-->
          </div>
        </div>
      </div>
      <!--end::Invitations-->
    </div>
    <!--end::Card-->
  </div></template
>

<script>
import { mapGetters } from "vuex";
import { utilMixin } from "@/mixins/utilMixin";
import { LOAD_USER_INVITATIONS } from "@/store/userinvitations.module";
import { LOAD_USER_COMPANY } from "@/store/company.module";

export default {
  name: "UserInvitations",
  mixins: [utilMixin],
  data() {
    return {
      userInvitations: {},
      company: {},
      isLoaded: false
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      await Promise.all([
        this.$store.dispatch(LOAD_USER_INVITATIONS),
        this.$store.dispatch(LOAD_USER_COMPANY)
      ]).then(() => {
        this.userInvitations = this.getUserInvitations;
        this.company = this.getUserCompany;
      });
      this.isLoaded = true;
    },
    getInvitationsStatusColor(userInvitation) {
      if (userInvitation.is_paid_out) {
        return "success";
      }
      if (userInvitation.is_discount_used) {
        return "primary";
      }
      return "warning";
    }
  },
  computed: {
    ...mapGetters(["getUserInvitations", "getUserCompany"])
  }
};
</script>

<style scoped></style>
